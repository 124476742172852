import { observable, action, computed } from "mobx";
import {
  getData,
  postData,
  patchData,
  authCurrentUser,
} from "../plugins/auth/auth";
import {
  ACTIVITY_TEMPLATES,
  ACTIVITY_TEMPLATES_ROUTE,
  ACTIVITIES,
  ACTIVITIES_ROUTE,
} from "../config";
import {
  IActivity,
  IActivityTemplate,
  INewActivity,
} from "../shared/interfaces";
import moment from "moment";
import _ from "lodash";

class ActivityStore {
  // Observables
  @observable
  public baseInit: any = {
    headers: {},
    response: true,
  };
  @observable
  public activityTemplates: IActivityTemplate[] = [];
  @observable
  public activeActivityTemplate = {};
  @observable
  public tasks: IActivity[] = [];
  @observable
  public routines: IActivity[] = [];

  @observable
  public tempTasks: IActivity[] = [];
  @observable
  public tempRoutines: IActivity[] = [];

  @observable
  public updateActivityModal = false;
  @observable
  public activeActivity = {};
  @observable
  public activeRoutine = {};
  @observable
  public createActivityModal = false;
  @observable
  public createActivityWhen = "thisWeek";
  @observable
  public createActivityType = "";
  @observable
  public createRoutineProgressModal = false;
  @observable
  public isGoalDetailForm = false;
  @observable
  public goalId = "";
  @observable
  public startDate = "";
  @observable
  public endDate = "";
  @computed get getWeekStartAndEnd() {
    const currentDate = new Date();
    const firstDayOfWeek = 0; // Sunday as the first day of the week. Use 1 for Monday.

    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(
      currentDate.getDate() - currentDate.getDay() + firstDayOfWeek
    );
    startOfWeek.setHours(0, 0, 0, 0); // Set to start of the day

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999); // Set to end of the day
    this.startDate = startOfWeek.toISOString();
    this.endDate = endOfWeek.toISOString();
    return { startOfWeek, endOfWeek };
  }

  // Computed Values
  @computed get activities() {
    return this.tasks.concat(this.routines);
  }

  @action.bound updateActivityType(type: string) {
    this.createActivityType = type;
  }

  @computed get usedActivityTemplateIds() {
    return _.map(this.activities, "templateId");
  }

  // Endpoint Actions
  @action.bound
  public async getActivityTemplates(goalTemplateId: string | null) {
    let url = ACTIVITY_TEMPLATES_ROUTE;
    if (goalTemplateId) url += `?goalTemplateId=${goalTemplateId}`;

    const res = await getData(ACTIVITY_TEMPLATES, url, this.baseInit).catch(
      (err) => {
        console.log("Error from getActivityTemplates: ", err);
      }
    );

    if (res && res.request.status === 200) {
      this.activityTemplates = res.data.activityTemplates;
      return this.activityTemplates;
    }
  }

  @action.bound
  public async getActivities(
    type: "task" | "routine",
    startDate?: string,
    endDate?: string,
    goalId?: string,
    completionType?: string
  ) {
    // if (!startDate) startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    if (startDate) startDate = moment(startDate).format("YYYY-MM-DD");
    if (endDate) endDate = moment(endDate).format("YYYY-MM-DD");
    // if (!endDate) endDate = moment().add(100, 'years').format("YYYY-MM-DD");
    // console.log(startDate,endDate)
    const dateParams =
      type === "task"
        ? `&startDate=${startDate && startDate.split("T")[0]}&endDate=${
            endDate && endDate.split("T")[0]
          }`
        : "";
    let url = `${ACTIVITIES_ROUTE}?type=${type}${
      completionType ? `&completionType=${completionType}` : ""
    }${startDate && endDate ? dateParams : ""}${
      completionType === "scheduled" ? "&status=incomplete" : ""
    }`;
    // let url = `${ACTIVITIES_ROUTE}?type=${type}${
    //   completionType === "all" ? "" : `&completionType=${completionType}`
    // }`;
    if (goalId) url += `&goalId=${goalId}`;

    const res = await getData(ACTIVITIES, url, this.baseInit).catch((err) => {
      console.log("Error from getActivities: ", err);
    });

    if (res && res.request.status === 200) {
      switch (type) {
        case "task":
          this.tasks = this._filterIsActive(res.data.activities);
          this.tempTasks = [];
          break;
        case "routine":
          this.routines = this._filterIsActive(res.data.activities);
          this.tempRoutines = [];
          break;
      }
    }
  }

  @action.bound
  public async createActivity(newActivity: INewActivity, updateCheckIn?: any) {
    let init = this.baseInit;
    const {
      name,
      description,
      goalId,
      templateId,
      frequency,
      type,
      completionDate,
      completionType,
    } = newActivity;
    const awsCurrentUser = await authCurrentUser();
    const userId = awsCurrentUser.signInUserSession.idToken.payload.sub;
    const atts = {
      ...(description.length > 0 && { description }),
      ...(typeof frequency == "number" && { frequency }),
      ...(templateId && { templateId }),
      ...(completionDate && {
        completionDate:
          completionType === "soon" || completionType === "someday"
            ? moment().format("YYYY-MM-DD")
            : completionDate === "scheduled"
            ? moment(new Date()).format("YYYY-MM-DD") //fix scheduled date
            : completionDate && completionDate.format("YYYY-MM-DD"),
      }),
      ...(completionType === "soon" || completionType === "someday"
        ? {
            completionType: completionType,
          }
        : {
            completionType: "scheduled",
          }),
    };
    init.body = {
      status: "incomplete",
      isActive: 1,
      name,
      type,
      goalId,
      completionDate,
      userId,
      ...atts,
    };

    let resErr;
    await postData(ACTIVITIES, ACTIVITIES_ROUTE, init)
      .then((res) => {
        if (goalId && window.location.pathname.includes("goals")) {
          switch (type) {
            case "task":
              this.tempTasks = [res.data, ...this.tempTasks];
              break;
            case "routine":
              this.tempRoutines = [res.data, ...this.tempRoutines];
              break;
          }
        } else if (goalId && window.location.pathname.includes("actions")) {
          switch (type) {
            case "task":
              this.tempTasks = [res.data, ...this.tempTasks];
              break;
            case "routine":
              this.tempRoutines = [res.data, ...this.tempRoutines];
              break;
          }
        } else if (updateCheckIn) {
          updateCheckIn(goalId, res.data);
        } else {
          this.getActivities("task");
          this.getActivities("routine");
        }
      })
      .catch((err) => {
        console.log("Error from createActivity: ", err);
        resErr = err.message;
      });

    if (resErr) {
      return resErr;
    }
  }

  @action.bound
  public async updateActivity(
    activity: IActivity,
    isActive: number = 1,
    updateCheckIn?: any
  ) {
    let init = this.baseInit;
    const { name, goalId, completionDate } = activity;
    const filteredActivity = _.omit(activity, ["description"]);
    const description =
      activity.description === undefined ? "" : activity.description;
    const atts = description.length === 0 ? {} : { description };
    const awsCurrentUser = await authCurrentUser();
    const userId = awsCurrentUser.signInUserSession.idToken.payload.sub;
    delete filteredActivity.goal;
    init.body = {
      ...filteredActivity,
      name,
      goalId,
      ...(completionDate && {
        completionDate: moment(completionDate).format("YYYY-MM-DD"),
      }),
      isActive,
      userId,
      ...atts,
    };

    let resErr;
    console.log("init body", init.body);
    const res = await patchData(
      ACTIVITIES,
      `${ACTIVITIES_ROUTE}/${activity.id}`,
      init
    )
      .then((res) => {
        if (updateCheckIn) {
          updateCheckIn(goalId, res.data);
        } else return res;
      })
      .catch((err) => {
        console.log("Error from updateActivity: ", err);
        resErr = err.message;
      });
    if (resErr) {
      return resErr;
    } else if (!updateCheckIn) {
      this._updateActivityStore(res.data);
    }
  }

  @action.bound
  public async resetGoalActivities(goalId: string) {
    let init = this.baseInit;
    init.body = {
      goalId,
    };

    let resErr;
    const res = await patchData(ACTIVITIES, "/reset-activities", init).catch(
      (err) => {
        console.log("Error from resetGoalActivities: ", err);
        resErr = err.message;
      }
    );

    if (resErr) {
      return resErr;
    } else {
      if (res.data.activities) {
        res.data.activities.map((activity: IActivity) =>
          this._updateActivityStore(activity)
        );
      }
    }
  }

  // Shared Actions

  @action.bound
  public _updateActivityStore(updatedActivity: IActivity) {
    if (updatedActivity.type === "task") {
      this._updateTaskStore(updatedActivity);
    } else if (updatedActivity.type === "routine") {
      this._updateRoutineStore(updatedActivity);
    } else {
      console.error("_updateActivityStore: Unknown activity type");
    }
  }

  @action.bound
  public _updateTaskStore(updatedTask: IActivity) {
    const newTasks = this.tasks.map((task) => {
      return task.id === updatedTask.id ? updatedTask : task;
    });

    this.tasks = this._filterIsActive(newTasks);
  }

  @action.bound
  public _updateRoutineStore(updatedRoutine: IActivity) {
    const newRoutines = this.routines.map((routine) => {
      return routine.id === updatedRoutine.id ? updatedRoutine : routine;
    });

    this.routines = this._filterIsActive(newRoutines);
  }

  @action.bound
  public _filterIsActive(activities: IActivity[]) {
    console.log("sorting");
    return activities
      .filter((activity: IActivity) => activity.isActive)
      .sort((a, b) => {
        let aPriority = a.goal && a.goal.isPriority ? a.goal.isPriority : 0;
        let bPriority = b.goal && b.goal.isPriority ? b.goal.isPriority : 0;
        // If priorities are the same, compare by date
        let dateComparison = 0;
        if (
          a.type === "task" &&
          b.type === "task" &&
          a.completionType === "scheduled" &&
          b.completionType === "scheduled"
        ) {
          dateComparison =
            Number(new Date(a.completionDate)) -
            Number(new Date(b.completionDate));
        }
        // Compare by priority first
        if (aPriority !== bPriority) {
          return bPriority - aPriority; // Higher priority comes first
        }

        return dateComparison;
      });
  }

  @action.bound
  public _promisedTimeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  @action.bound
  public getAllActivities = async () => {
    this.getActivities("task", undefined, undefined, undefined, "scheduled");
    this.getActivities("routine");
  };
}

export default new ActivityStore();
